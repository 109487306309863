import React, { useContext, useEffect } from "react";

import { MixpanelContext } from "../mixpanel_tracking";
import { Link, graphql } from "gatsby";
import "../index.css";
import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";

const BlogIndex = ({ data, location }) => {
  const mixpanel = useContext(MixpanelContext);
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1400 });
    return isDesktop ? children : null;
  };
  // Runs once, after page load
  useEffect(() => {
    mixpanel.track("Load homepage");
  }, [mixpanel]);
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1400 });
    return isMobile ? children : null;
  };

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Crustdata Media: Data-driven business publication for the thinkers" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    );
  } else {
    return (
      <div className="index-main">
        <Layout location={location} title={siteTitle}>
          <SEO title="Crustdata Media: Data-driven business publication for the thinkers" />
          <Desktop>
            <div className="main-post-container">
              <ol className="post-ordered-list" style={{ listStyle: `none` }}>
                {posts.map((post, i) => {
                  const image = getImage(post.frontmatter.thumbnail);
                  const title = post.frontmatter.title || post.fields.slug;

                  return (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={post.fields.slug}
                      itemProp="url">
                      <li
                        className="post-flex-list-index"
                        key={post.fields.slug}>
                        <div className="post-thumbnail">
                          <a
                            className={`category-badge ${post.frontmatter.categories}`}>
                            <span>
                              {_.upperCase(post.frontmatter.categories)}
                            </span>
                          </a>
                          {/* <img src={post.frontmatter.thumbnail.publicURL} /> */}
                          <GatsbyImage
                            image={image}
                            alt={post.frontmatter.description}
                          />
                        </div>
                        <div className="post-content">
                          <article
                            className="post-list-item"
                            itemScope
                            itemType="http://schema.org/Article">
                            <header>
                              <h2>
                                <span
                                  className="post-heading"
                                  itemProp="headline">
                                  {title}
                                </span>
                              </h2>
                              <small>{post.frontmatter.date}</small>
                            </header>
                            <section>
                              <p
                                className="description"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    post.frontmatter.description ||
                                    post.excerpt,
                                }}
                                itemProp="description"
                              />
                            </section>
                          </article>
                        </div>
                      </li>
                    </Link>
                  );
                })}
              </ol>
              <div className="view-all-posts">
                <Link className="nav-link" to="/posts">
                  View All
                </Link>
              </div>
            </div>
          </Desktop>
          <Mobile>
            <div className="mobile-main-container">
              <ol className="post-ordered-list" style={{ listStyle: `none` }}>
                {posts.map((post, i) => {
                  const image = getImage(post.frontmatter.thumbnail);
                  const title = post.frontmatter.title || post.fields.slug;

                  return (
                    <a
                      style={{ textDecoration: "none" }}
                      href={post.fields.slug}
                      itemProp="url">
                      <li
                        className="mobile-post-flex-list-index"
                        key={post.fields.slug}>
                        <div className="post-thumbnail">
                          <a
                            className={`category-badge ${post.frontmatter.categories}`}>
                            <span>
                              {_.upperCase(post.frontmatter.categories)}
                            </span>
                          </a>
                          {/* <img src={post.frontmatter.thumbnail.publicURL} /> */}
                          <GatsbyImage
                            image={image}
                            alt={post.frontmatter.description}
                          />
                        </div>
                        <div className="mobile-post-content">
                          <article
                            className="post-list-item"
                            itemScope
                            itemType="http://schema.org/Article">
                            <header>
                              <h2>
                                <span
                                  className="post-heading"
                                  itemProp="headline">
                                  {title}
                                </span>
                              </h2>
                              <small>{post.frontmatter.date}</small>
                            </header>
                            <section>
                              <p
                                className="description"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    post.frontmatter.description ||
                                    post.excerpt,
                                }}
                                itemProp="description"
                              />
                            </section>
                          </article>
                        </div>
                      </li>
                    </a>
                  );
                })}
              </ol>
              <div className="view-all-posts">
                <Link className="nav-link" to="/posts">
                  View All
                </Link>
              </div>
            </div>
          </Mobile>
        </Layout>
      </div>
    );
  }
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 6, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          categories
          tags
          thumbnail {
            id
            absolutePath
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
